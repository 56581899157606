@import '../../../../styles/customMediaQueries.css';

.p {
  max-width: 80ch;
  line-height: 1.66;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0;

  /* Handle margin-top of next adjacent element against this p element */
  & + p,
  & + ul,
  & + ol,
  & + pre,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 24px;
  }
}

.buttonLink {
  display: inline-block;
  padding: 8px 20px;
  font-size: 15px;
  background-color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
  border-radius: 4px;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top: 12px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    color: white;
    border-color: var(--marketplaceColorDark);
    background-color: var(--marketplaceColorDark);
  }
}

.pMarkdown {
  composes: p;

  & > em {
    display: block;
    margin-top: 6px;
    @media (--viewportMedium) {
      display: inline;
      margin: 0 6px;
    }
  }

  & > a:hover {
    text-decoration: none;
    border: none;
    box-shadow: none;
    color: var(--marketplaceColorDark);
  }

  & > a:first-child:not(:only-of-type) {
    display: inline-block;
    padding: 8px 20px;
    border: 1px solid var(--marketplaceColor);
    border-radius: var(--borderRadiusMedium);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    margin-top: 12px;

    background-color: var(--marketplaceColor);

    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    white-space: nowrap;
    color: var(--colorWhite);

    &:hover {
      color: var(--colorWhite);
      background-color: var(--marketplaceColorDark);
    }
  }

  & > a:last-child:not(:only-of-type) {
    display: inline-block;
    padding: 8px 20px;
    border: 1px solid var(--marketplaceColor);
    border-radius: var(--borderRadiusMedium);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    margin-top: 12px;

    background-color: var(--marketplaceColor);

    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    white-space: nowrap;

    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid var(--colorWhite);
    color: var(--marketplaceColor);
    box-shadow: none;
    text-decoration: none;

    &:hover {
      color: var(--marketplaceColorDark);
      background-color: var(--colorWhite);
    }
  }
}
