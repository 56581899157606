@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
  text-align: center;

  h1 {
    background-color: rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 2em;
  }
  ul {
    text-align: left;
    width: 70%;
    margin: 0 auto;
  }
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;

  & p > a:only-of-type {
    display: inline-block;
    padding: 8px 20px;
    white-space: nowrap;
    border-radius: var(--borderRadiusMedium);

    background-color: transparent;
    color: var(--marketplaceColor);
    box-shadow: none;

    &:hover {
      background-color: transparent;
      color: var(--marketplaceColorDark);
      box-shadow: var(--marketplaceColor) 0 0 0 1px;
    }
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
