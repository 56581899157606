@import '../../../../styles/customMediaQueries.css';

.root {
    .accordion {
        overflow: hidden;
    }

    .accordionItem + .accordionItem {
        margin-top:8px;
    }

    .accordionButton {
        background-color: rgba(0,0,0,0.02);
        color: var(--marketplaceColor);
        cursor: pointer;
        padding: 18px 18px 18px 40px;
        line-height: 1.4;
        width: 100%;
        text-align: left;
        border: none;
        border-radius: 8px 8px 0 0;

    }

    .accordionButton:hover {
        text-decoration: underline;
    }

    .accordionButton:before {
        position: absolute;
        margin-left: -24px;
        margin-top: 4px;
        content: '';
        height: 10px;
        width: 10px;
        margin-right: 12px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(-45deg);
        transition: var(--transitionStyleButton);

    }

    .accordionButton[aria-expanded='true']::before,
    .accordionButton[aria-selected='true']::before {
        transform: rotate(45deg);
    }

    [hidden] {
        display: none;
    }

    .accordionPanel {
        padding: 20px;
        animation: accordionFadein 0.35s ease-in;
        box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        border-radius: 0 0 8px 8px;
        background-color: rgba(255,255,255,0.5);
        margin-bottom: 20px;

        @media (--viewportMedium) {
            padding: 20px 20px 20px 40px;
        }
    }

    /* -------------------------------------------------- */
    /* ---------------- Animation part ------------------ */
    /* -------------------------------------------------- */

    @keyframes accordionFadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}